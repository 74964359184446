
import { Component, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { FileIcon } from 'vue-feather-icons';
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mixins } from 'vue-class-component';
import PdfViewer from '@/modules/guests/components/PdfViewer.vue';
import { guestModule } from '@/store/modules/guest.module';
import { uploadNotify } from '@/shared/constants';
import LoadMixin from '@/shared/mixins/load.mixin';
import { Loading } from '@/modules/auth/store/auth.service';
import Signature from '@/components/signature/Signature.vue';
import { startLoading, stopLoading } from '@/app/utils/loading';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { loginRequest } from '@/app/config/msal/request.config';
import travelSchema from '../schema/travel.schema';
import healthSchema from '../schema/health-check';
import VsDivider from '../../../components/vx-divider/vsDivider.vue';
import { Auth } from '@/store/modules/auth.module';
import Guest, { GuestAnswers } from '../../../app/entities/tenant/guest.dto';
import { AccommodationDto, AccommodationDtoDocuments } from '@/app/entities/tenant/accommodation.dto';
import service from '../../../services/app-http-client';
import GuestHealthCheckForm from '../../guests/components/GuestHealthCheckForm.vue';
import * as _ from 'lodash';
import { POSITION } from 'vue-toastification';

interface VExtType extends Vue {
  [k: string]: any;
}

interface healthRef {
  formSubmitted(): boolean;
  hideErrors(): any;
  hasErrors: boolean;
}

@Component({
  metaInfo: {
    title: 'Health Screening',
    // override the parent template and just use the above title only
  },
  components: {
    PdfViewer,
    FileIcon,
    Signature,
    VuePerfectScrollbar,
    VsDivider,
    GuestHealthCheckForm,
  },
})
export default class GuestCheckinModal extends mixins(LoadMixin) {
  public $refs: Vue['$refs'] & {
    signaturePad: VExtType;
    guestHealthCheckForm: GuestHealthCheckForm;
  };

  healthForm = healthSchema;

  travelForm = travelSchema;

  open = false;

  accommodation: AccommodationDto = {} as AccommodationDto;

  title = '';

  documentCheckbox = true;

  guest = new Guest();

  options = {
    penColor: '#c0f',
  };

  settings = {
    maxScrollbarLength: 60,
  };

  pdfArr: AccommodationDtoDocuments[] = [];

  finalCheckBox = false;

  formModelvar = {};

  scrolledToBottom = true;

  signature = '';

  signatureBool = false;

  loading = false;

  selectedIndex = 0;

  contentProgress = 0;

  steps = 0;

  token = '';

  formError = null;

  @Watch('selectedIndex')
  scrollToTop(): void {
    document.body.scrollTop = 0;
  }
  get hasSignature() {
    return this.signatureBool;
  }
  formErrorFunction(val: any) {
    this.formError = val;
  }

  get flippedHealthQuestions() {
    return this.guest.guestAnswers;
  }

  get progressColor() {
    if (this.contentProgress <= 25) {
      return 'teal-300';
    }
    if (this.contentProgress <= 75) {
      return 'teal-500';
    }
    return 'teal-700';
  }

  get url() {
    return `${process.env.VUE_APP_API_URL}/v1/api/guest/identification/${tenantModule.apiKey}/${this.guest.bookingId}/${this.guest.booking?.accommodationId}/${this.guest.id}`;
  }

  increasePercentage() {
    this.steps += 1;
    if (this.contentProgress <= 80) {
      this.contentProgress += 25;
    }
  }

  decreasePercentage() {
    this.steps -= 1;
    if (this.contentProgress >= 20) {
      this.contentProgress -= 25;
    }
  }

  async created() {
    const load = this.$vs.loading({
      type: 'circles',
      color: 'primary',
      text: 'Redirecting to health questionnaire...',
    });
    /*     this.loading = true;
     */ const to = this.$router.currentRoute;
    this.token = to.query.id as string;
    const organisationId = to.query.org;

    /*     loginRequest.state = `${process.env.VUE_APP_URL} + /guests/check-in?id=${this.token}&org=${organisationId}`;
     */
    loginRequest.state = 'guest';

    localStorage.setItem('pendingInvite', this.token as string);
    tenantModule.setTenantId([organisationId as string]);
    this.$msal.data.accessToken = this.token;

    await guestModule.fetchGuest({
      token: this.token,
    });
    this.pdfArr = this.guest?.booking?.accommodation?.accommodationDocuments || [];
    this.accommodation = guestModule.booking.accommodation;
    this.guest = guestModule.guest;
    this.formModelvar = this.guest;

    if (this.guest.status === 'PENDING') {
      this.$router.push({ path: '/guests/booking', query: { id: this.token, org: organisationId, preCheck: 'true' } });
    }
    if (this.guest.status === 'CHECKEDIN') {
      this.$swal('Health check already completed', 'You have already completed and submited your health check.', 'error');
      this.$router.push('/');
    }

    /*     this.loading = false;
    const load = this.$vs.loading(); */
    load.close();
  }

  pdfbutton(pdf: any): void {
    this.title = pdf.name;
    this.open = true;
  }

  validateStep() {
    return this.documentCheckbox;
  }

  changeGuest(data: Record<string, any>, index: number) {
    this.formModelvar = data as any;
    this.selectedIndex = index;
  }

  save(): void {
    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

    if (!isEmpty) {
      this.signature = data;
    }
  }

  undo() {
    this.$refs.signaturePad.undoSignature();
  }

  onBegin() {
    this.$refs.signaturePad.resizeCanvas();
  }

  validateFinalStep() {
    if (this.finalCheckBox) return true;
    return false;
  }

  acceptFunc() {
    this.scrolledToBottom = true;
  }

  // healthCheckChange(index: number) {
  //   this.formModelvar = this.guest.minor[index];
  // }

  mapAnswers(payload: any) {
    return Object.keys(payload).map((key) => {
      return {
        answer:
          typeof payload[key] === 'string'
            ? [{ value: payload[key] }]
            : payload[key].map((v: any) => {
                return {
                  value: v,
                };
              }),

        healthQuestionsId: parseInt(key),
      };
    });
  }

  async changeForm(index: number, guest: any) {
    const load = this.$vs.loading();
    const healthForm = this.$refs.guestHealthCheckForm as any;
    const res = await healthForm.formSubmit();
    if (res) {
      load.close();
      return this.$swal('Error submitting form', 'Please fill out all fields before submitting', 'error');
    }
    if (guest !== 'done') {
      if (this.selectedIndex > index) {
        this.formModelvar = guest as any;
        this.selectedIndex = index;
        load.close();
      } else {
        if (!this.formError) {
          this.formModelvar = guest as any;
          // this.$swal('Form completed', 'Please fill out the next form for ' + guest.firstName, 'info');
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: `Form completed, please fill out the next form for ${guest.firstName}`,
                icon: 'concierge-bell',
                variant: 'success',
              },
            },
            {
              position: POSITION.TOP_RIGHT,
            },
          );
          this.selectedIndex = index;
          load.close();
        }
      }
    } else {
      this.selectedIndex = index;
      load.close();
    }
  }
  async validateForm() {
    /* const healthForm = this.$refs.guestHealthCheckForm;
    const isValidated = await healthForm.formSubmit(); */

    if (this.hasSignature) {
      this.submitForm();
    } else {
      this.$swal('Error submitting form', 'Please sign before submitting', 'error');
    }
  }

  async submitForm(): Promise<void> {
    const load = this.$vs.loading({
      type: 'circles',
      color: 'primary',
      text: 'Submitting health questionnaire...',
    });
    // TOTO chage upload on backend, just updates current signatiure
    // await this.upload();

    this.guest.status = 'CHECKEDIN';
    const payload = _.cloneDeep(this.guest);
    const guestId = this.guest.id + 'checkin';
    payload.healthSignature = await guestModule.uploadImage({
      signature: this.guest.healthSignature,
      id: guestId,
      bookingId: this.guest.bookingId,
    });
    payload.guestAnswers = this.mapAnswers(payload.guestAnswers);

    payload.minor.forEach((minor, index) => {
      payload.minor[index].guestAnswers = this.mapAnswers(minor.guestAnswers);
    });

    try {
      await service.post(`guest/answer/${this.guest.id}`, payload.guestAnswers, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'x-organisation-id': `${tenantModule.apiKey}`,
        },
      });

      const guestData = { guestInfo: payload, profile: payload.profile };
      await service.put(`guest/update/${this.guest.id}`, guestData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'x-organisation-id': `${tenantModule.apiKey}`,
        },
      });

      if (this.$router.currentRoute.query.preCheck) {
        load.close();
        this.$swal(
          'Health Screening and Check-in Complete',
          'You have now submitted your health screening questionnaire and completed your online pre check-in ',
          'success',
        );
      } else {
        load.close();
        this.$swal('Health Screening Complete', 'You have now submitted your health screening questionnaire and checked-in. ', 'success');
      }
      this.$router.replace('/healthscreen-confirmation');
    } catch (error: any) {
      load.close();
    }

    load.close();
    // await guestModule.startCheckIn({ guestInfo: guestDto });
    // this.$router.push('/');
    // }
  }

  scrollUp() {
    this.scrolledToBottom = false;
  }

  get minor() {
    return this.guest.minor?.length ?? -1;
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }
  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-gray.webp');
  }
}
