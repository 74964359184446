export default [
  {
    component: 'div',
    class: 'mt-5 md:mt-0 md:col-span-2',
    children: [
      {
        type: 'range',
        name: 'fitnessLevel',
        label: 'Please rate your fitness level on a score of 1-5',
        min: 0,
        max: 5,
        value: 3,
        'validation-name': 'Fitness',
        validation: 'required',
        'show-value': true,
        class: 'py-1',
      },
      {
        type: 'text',
        name: 'physicalImpairments',
        label: 'Do you have any physical impairments?',
        class: 'py-1',
        options: "{first: 'First', second: 'Second', third: 'Third', fourth: 'Fourth'}",
      },
      {
        type: 'checkbox',
        name: 'cardiovascularDisease',
        label: 'Cardiovascular Disease',
        placeholder: 'Cardiovascular Disease',
        class: 'py-1',
      },
      {
        type: 'checkbox',
        name: 'hypertension',
        label: 'Hypertension',
        class: 'py-1',
      },
      {
        type: 'checkbox',
        name: 'smoking',
        label: 'Are you a smoker?',
        class: 'py-1',
      },
    ],
  },
];
