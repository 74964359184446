
import Component from 'vue-class-component';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Vue from 'vue';
import dailySchema from '@/modules/guests/schema/daily-check';
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator';
import { guestModule } from '@/store/modules/guest.module';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';

interface healthRef {
  formSubmitted(): Promise<boolean>;
  hideErrors(): any;
  hasErrors: boolean;
}

@Component
export default class GuestHealthCheckForm extends Vue {
  @Prop() guest: any;

  public $refs: Vue['$refs'] & {
    healthRef: healthRef;
  };

  accommodation: AccommodationDto = {} as AccommodationDto;

  @Watch('guest.guestAnswers')
  handleNone() {
    for (const key in this.guest.guestAnswers) {
      let answersKey = this.guest.guestAnswers[key];
      if (Array.isArray(answersKey)) {
        if (answersKey.find((e) => e.includes('None'))) {
          const noneAbove: string = answersKey.find((e) => e.includes('None'));
          if (answersKey[0] !== noneAbove) {
            answersKey.length = 0;
            answersKey.unshift(noneAbove);
          } else if (answersKey[0] === noneAbove && answersKey.length !== 1) {
            answersKey.splice(0, 1);
          }
        }
      }
    }
  }

  created() {
    this.accommodation = guestModule.booking.accommodation;
  }

  @Emit('hasErrors')
  async formSubmit() {
    return new Promise((res) => {
      const healthRef: healthRef = this.$refs.healthRef;

      healthRef.formSubmitted().then(() => {
        res(healthRef.hasErrors);
      });
    });
  }

  get sortedHealthQuestions() {
    for (var i = 0; i < this.accommodation.healthQuestions.length; i++) {
      for (var j = 0; j < this.accommodation.healthQuestions[i].question.options.option.length; j++) {
        if (this.accommodation.healthQuestions[i].question.options.option[j].label == 'None of the above') {
          this.accommodation.healthQuestions[i].question.options.option.push(
            this.accommodation.healthQuestions[i].question.options.option.splice(j, 1)[0],
          );
        }
      }
    }
    return this.accommodation.healthQuestions.sort((a: any, b: any) => (a.question.order < b.question.order ? -1 : 1));
    /*  return this.accommodation.healthQuestions.forEach((a: any) => (if(a.question.options.option[i].label == "None of the above"){
      a.question.options.option[i].push(a.question.options[i].option.splice(i, 1)[0]));
 })));*/
  }

  isValidate() {
    // if (question.questionId === 5) return 'bail';
    return 'bail|required';
  }

  validationName() {
    // if (item.questionId === 3) return 'Conditions';
    // return item.question.subtext;
    return 'The above field';
  }
}
