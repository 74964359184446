export default [
  {
    component: 'div',
    class: 'mt-5 md:mt-0 md:col-span-2 text-center',
    children: [
      {
        type: 'textarea',
        name: 'travelRecently',
        label: 'Have you travelled internationality in the last 30 days?',
        'validation-name': 'Fitness',
        validation: 'required',
        class: 'py-2',
      },
      {
        component: 'span',
        children: 'Are you awaiting the results of a covid test?',
        class: 'py-2',
      },
      {
        component: 'div',
        class: 'flex flex-wrap justify-evenly',
        children: [
          {
            type: 'checkbox',
            name: 'recentTest',
            label: 'Yes',
            class: 'py-2',
          },
          {
            type: 'checkbox',
            name: 'recentTest',
            label: 'No',
            class: 'py-2',
          },
        ],
      },
      {
        component: 'span',
        children:
          'In the last 14 days have you been in contact with anyone who has tested positive for COVID-19 or is awaiting the result of a COVID-19 test?',
        class: 'py-2',
      },
      {
        component: 'div',
        class: 'flex flex-wrap justify-evenly',
        children: [
          {
            type: 'checkbox',
            name: 'contactRecently',
            label: 'Yes',
            class: 'py-2',
          },
          {
            type: 'checkbox',
            name: 'contactRecently',
            label: 'No',
            class: 'py-2',
          },
        ],
      },

      {
        component: 'span',
        children:
          'Do you have travel insurance which covers your medical/quarantine costs in the event you come into contact with COVID-19 positive people or contract COVID-19? (For international visitors only)?',
        class: 'py-2',
      },
      {
        component: 'div',
        class: 'flex flex-wrap justify-evenly',
        children: [
          {
            type: 'checkbox',
            name: 'healthInsurance',
            label: 'Yes',
            class: 'py-2',
          },
          {
            type: 'checkbox',
            name: 'healthInsurance',
            label: 'No',
            class: 'py-2',
          },
        ],
      },
    ],
  },
];
